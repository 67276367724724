import axios from 'axios'
import constants from '@/constants'

export default axios.create({
  baseURL: `${constants.BASE_API}${constants.COMPANY_API_PREFIX}`,
  headers: {
    'Content-type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
})

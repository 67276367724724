export default function checkRoute(id) {
  if (localStorage.getItem('permission') == null) {
    return false
  }
  const keyArr = localStorage.getItem('permission').split(',')
  let isValidPermission = false
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keyArr.length; i++) {
    if (id === keyArr[i]) {
      isValidPermission = true
    }
  }
  if (!isValidPermission) {
    return false
  }
  return true
}

<template>
  <b-col xl="12">

    <b-card>
      <b-card-title class="">
        {{ t('Lead Details') }}
      </b-card-title>
      <b-row>
        <b-col cols="6">
          <b-row class="mx-0">
            <!-- Company-->
            <b-col cols="6">
              <b-form-group
                label-for="vi-company"
                class="cursor-pointer-checkbox"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Comapny"
                  vid="vi-company"
                  rules=""
                >
                  <b-form-radio
                    v-model="form.lead_generation_type"
                    name="company-individual"
                    value="1"
                    :disabled="edit_lead_id ? true : false"
                    @change="handleForm"
                  >
                    <span
                      class="cursor-pointer-checkbox"
                    >{{ t('Company') }}</span>
                  </b-form-radio>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Individual -->
            <b-col cols="6">
              <b-form-group
                label-for="vi-individual"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Individual"
                  vid="vi-individual"
                  rules=""
                >
                  <b-form-radio
                    v-model="form.lead_generation_type"
                    name="company-individual"
                    value="2"
                    :disabled="edit_lead_id ? true : false"
                    @change="handleForm"
                  >
                    {{ t('Individual') }}
                  </b-form-radio>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Select -->
          <b-col cols="12">
            <b-form-group
              :label="t(company_individual_select)"
              label-for="vi-company-individual-select"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="company Individual Select"
                vid="vi-company_individual_select"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <!-- <v-select
                  v-model="form.reporting_person"
                  class="form-control p-0 border-0"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  placeholder=""
                  :options="[]"
                  @change="handleForm"
                /> -->
                  <vue-autosuggest
                    ref="company_select"
                    v-model="form.vendor_name"
                    :class="errors[0] ? 'border-red-selector' : ''"
                    :suggestions="suggestions"
                    :input-props="{id: 'autosuggest__input_ajax',
                                   placeholder: t('Enter/Select'),
                                   class: 'form-control',
                                   disabled:company_select_disable}"
                    :render-suggestion="renderSuggestion"
                    @selected="change_autosuggest"
                    @input="fetchResults"
                  />
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Select Customer_type -->
          <b-col cols="12">
            <b-form-group
              :label="t('Customer Type')"
              label-for="vi-Customer_type"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="Customer type"
                vid="vi-Customer_type"
                rules="required"
              >
                <v-select
                  v-model="form.customer_type_id"
                  :class="errors[0] ? 'border-red-vselect' : ''"
                  class="p-0 border-0"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="customer_type"
                  placeholder=""
                  :reduce="ct => ct.id"
                  :options="customer_type_list"
                  @input="handleForm"
                />
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Address -->
          <b-col cols="12">
            <b-form-group
              :label="t('Address')"
              label-for="vi-address"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="Address Line 1"
                vid="vi-address"
                :rules="form.address ? '' : 'required'"
              >
                <b-input-group
                  v-model="form.address"
                  class="input-group-merge"
                  :class="errors[0] ? 'border-red-gmap' : ''"
                >
                  <GmapAutocomplete
                    id="lead-address"
                    label-for="vi-address"
                    class="form-control"
                    :placeholder="t('Enter a Location')"
                    @keydown.enter.prevent
                    @place_changed="setPlace"
                    @change="changeAddress"
                  />

                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-row class="mx-0">
            <!-- Country -->
            <b-col cols="6">
              <b-form-group
                :label="t('Country')"
                label-for="vi-Country"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  vid="vi-Country"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-Country"
                      v-model="form.country_name"
                      :disabled="country_disable"
                      :class="errors[0] ? 'border-red' : ''"
                      type="text"
                      autocomplete="off"
                      :placeholder="t('Country')"
                      @input="handleForm"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- State -->
            <b-col cols="6">
              <b-form-group
                :label="t('State')"
                label-for="vi-State"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="State"
                  vid="vi-State"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-State"
                      v-model="form.state_name"
                      :disabled="state_disable"
                      :class="errors[0] ? 'border-red' : ''"
                      type="text"
                      autocomplete="off"
                      :placeholder="t('State')"
                      @input="handleForm"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- City -->
            <b-col cols="6">
              <b-form-group
                :label="t('City')"
                label-for="vi-City"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="City"
                  vid="vi-City"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-City"
                      v-model="form.city_name"
                      :disabled="city_disable"
                      :class="errors[0] ? 'border-red' : ''"
                      type="text"
                      autocomplete="off"
                      :placeholder="t('City')"
                      @input="handleForm"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- zipcode -->
            <b-col cols="6">
              <b-form-group
                :label="t('Zip Code')"
                label-for="vi-zipcode"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Zip code"
                  vid="vi-zipcode"
                  rules="required|min:6"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-zipcode"
                      v-model="form.zipcode"
                      :disabled="zip_disable"
                      type="text"
                      autocomplete="off"
                      :class="errors[0] ? 'border-red' : ''"
                      :placeholder="t('Zip Code')"
                      @input="handleForm"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- LandLine -->
            <b-col cols="8">
              <b-form-group
                :label="t('Landline')"
                label-for="vi-Landline"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Landline"
                  vid="vi-Landline"
                  rules="integer|min:10"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-Landline"
                      v-model="form.landline"
                      type="text"
                      maxlength="15"
                      autocomplete="off"
                      :class="errors[0] ? 'border-red' : ''"
                      :placeholder="t('Landline')"
                      @keypress="onlyNum"
                      @input="handleForm"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ext -->
            <b-col cols="4">
              <b-form-group
                :label="t('Ext.')"
                label-for="vi-ext"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Ext."
                  vid="vi-ext"
                  rules="integer|min:3"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-ext"
                      v-model="form.ext"
                      type="text"
                      maxlength="10"
                      autocomplete="off"
                      :class="errors[0] ? 'border-red' : ''"
                      :placeholder="t('Ext.')"
                      @keypress="onlyNum"
                      @input="handleForm"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
          <!-- Website -->
          <b-col cols="12">
            <b-form-group
              :label="t('Website')"
              label-for="vi-Website"
              class=""
            >
              <validation-provider
                #default="{ errors }"
                name="Website"
                vid="vi-Website"
                rules="url"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-Website"
                    v-model="form.website"
                    :class="errors[0] ? 'border-red' : ''"
                    type="text"
                    autocomplete="off"
                    maxlength="100"
                    :placeholder="t('Website must includes https://')"
                    @input="handleForm"
                  />
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- remarks -->
          <b-col cols="12">
            <b-form-group
              :label="t('Remarks')"
              label-for="vi-remarks"
              class=""
            >
              <validation-provider
                #default="{ errors }"
                name="Remarks"
                vid="vi-remarks"
                rules=""
              >
                <b-input-group class="input-group-merge">
                  <b-form-textarea
                    id="vi-remarks"
                    v-model="form.remark"
                    :class="errors[0] ? 'border-red' : ''"
                    :placeholder="t('Remarks')"
                    rows="3"
                    autocomplete="off"
                    @input="handleForm"
                  />
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- otherService -->
          <b-col
            v-if="otherServiceTextField"
            cols="12"
          >
            <b-form-group
              :label="t('Other Service')"
              label-for="vi-otherService"
              class=""
            >
              <validation-provider
                #default="{ errors }"
                name="Other Service"
                vid="vi-otherService"
                rules=""
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="vi-otherService"
                    v-model="form.other_service"
                    :class="errors[0] ? 'border-red' : ''"
                    :placeholder="t('Other Service')"
                    rows="3"
                    autocomplete="off"
                    @input="handleForm"
                  />
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-col>
        <b-col cols="6">
          <GmapMap
            :center="center"
            :zoom="12"
            style="width: 100%; height: 360px; margin-top: 20px"
          >
            <GmapMarker
              :position="markers"
              @click="center = markers"
            />
          </GmapMap>

          <!-- Select Services -->
          <b-col cols="12">
            <b-form-group
              :label="t('Services')"
              label-for="vi-service_name"
              class="pt-2 required"
            >
              <validation-provider
                #default="{ errors }"
                name="Services"
                vid="vi-service_name"
                rules="required"
              >
                <b-form-radio-group
                  class="row"
                  size="sm"
                >
                  <b-form-checkbox
                    v-for="option in services"
                    :key="option.value"
                    v-model="form.lead_service"
                    :value="option.id"
                    class="col-md-6 my-1"
                    @change="handleFormm()"
                  >
                    {{ option.service_name }}
                  </b-form-checkbox>
                </b-form-radio-group>
                <!-- <v-select
                  v-model="form.lead_service"
                  class="p-0 border-0"
                  :class="errors[0] ? 'border-red-vselect' : ''"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="service_name"
                  :reduce="s => s.id"
                  multiple
                  placeholder=""
                  :options="services"
                  @option:selected="handleFormm"
                  @option:deselected="handleFormRemove"
                /> -->
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-col>
      </b-row>
    </b-card>
  </b-col>

</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import {
  BCard, BCol, BFormRadio, BRow, BFormGroup, BInputGroup, BCardTitle, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import { useUtils } from '@core/libs/i18n'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import { onlyNumber } from '@/utils/common'
import constants from '@/constants'
// import LoginDetails from './CreateUserForm/LoginDetails.vue'

export default {
  name: 'LeadDetails',
  components: {
    VueAutosuggest,
    vSelect,
    BFormInput,
    BFormRadio,
    BCardTitle,
    BRow,
    ValidationProvider,
    BCol,
    BFormGroup,
    BInputGroup,
    BCard,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: ['req', 'editdata', 'formval', 'edit_lead_id'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      center: { lat: 23.0665966, lng: 72.5321012 },
      required,
      currentPlace: null,
      markers: {},
      company_select_disable: true,
      suggestions: [],
      company_individual_select: 'Company/Individual',
      otherServiceTextField: false,
      form: {
        lead_id: '',
        company_individual: '',
        vendor_name: '',
        zipcode: '',
        latitude: '',
        longitude: '',
        city_name: '',
        state_name: '',
        country_id: '',
        country_name: '',
        lead_service: [],
        remark: '',
        ext: '',
        customer_type_id: '',
        landline: '',
        website: '',
        lead_generation_type: '',
        vendor_id: '',
        address: '',
        address_line_2: '',
        landmark: '',
        preview_message: [],
        other_service: '',
        contact_data: [{
          id: 1,
          department: '',
          designation: '',
          email: '',
          ext: '',
          landline: '',
          lead_contact_id: '',
          mobile: '',
          name: '',
          assign_service: [],

        }],
      },
      country: [],
      contact_service: [],
      services: [],
      customer_type_list: [],
      country_disable: false,
      state_disable: false,
      city_disable: false,
      zip_disable: false,
      statusOptions: [
        {
          title: 'Active',
          value: 'A',
        },
        {
          title: 'In-Active',
          value: 'I',
        },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    editdata(newVal) {
      this.form = newVal
    },
    formval: {
      deep: true,
      handler(val) {
        this.form = val
        this.markers = { lat: parseFloat(val.latitude), lng: parseFloat(val.longitude) }
        this.center = { lat: parseFloat(val.latitude), lng: parseFloat(val.longitude) }
      },
    },
  },
  mounted() {
    store.dispatch('ManageLeads/getCustomersTypeList')
      .then(response => {
        if (response.data.code === '200') {
          this.customer_type_list = response.data.data
        }
      })
    store.dispatch('CompanyuserManage/getServices').then(response => {
      if (response.data.code === '200') {
        this.services = response.data.data
      }
    })
    store.dispatch('getCountryList/getCountryList')
      .then(response => {
        if (response.data.code === '200') {
          this.country = response.data.data
        }
      })
  },
  methods: {
    changeAddress(val) {
      if (val.target.value === '') {
        this.form.zipcode = ''
        this.zip_disable = false
        this.form.country_name = ''
        this.country_disable = false
        this.form.city_name = ''
        this.city_disable = false
        this.form.state_name = ''
        this.state_disable = false
      }
    },
    setPlace(place) {
      this.currentPlace = place
      this.form.address = place.formatted_address
      this.form.city_name = ''
      this.form.state_name = ''
      this.form.country_name = ''
      this.form.zipcode = ''
      this.form.latitude = place.geometry.location.lat()
      this.form.longitude = place.geometry.location.lng()
      place.address_components.forEach(address => {
        if (address.types.includes('postal_code')) {
          this.form.zipcode = address.long_name
        }
        if (address.types.includes('administrative_area_level_1')) {
          this.form.state_name = address.long_name
        }
        if (address.types.includes('locality')) {
          this.form.city_name = address.long_name
        }
        if (address.types.includes('country')) {
          this.form.country_name = address.long_name
        }
      })
      if (this.form.zipcode !== '') { this.zip_disable = true } else { this.zip_disable = false }
      if (this.form.country_name !== '') { this.country_disable = true } else { this.country_disable = false }
      if (this.form.city_name !== '') { this.city_disable = true } else { this.city_disable = false }
      if (this.form.state_name !== '') { this.state_disable = true } else { this.state_disable = false }
      this.addMarker()
      this.$emit('getCreateLead', this.form)
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        }
        this.markers = marker
        this.center = ''
        this.center = marker
        this.currentPlace = null
      }
    },
    handleFormRemove(val) {
      this.$emit('getCreateLead', this.form)
      this.$emit('contactRemoveServices', val)
    },
    handleFormm() {
      this.$emit('getCreateLead', this.form)
      // eslint-disable-next-line prefer-const
      let filterservices = []
      this.form.lead_service.forEach(element => {
        // eslint-disable-next-line prefer-destructuring
        filterservices.push(this.services.filter(s => s.id === element)[0])
      })
      this.contact_service = [...new Map(filterservices.map(item => [item.id, item])).values()]
      if (this.contact_service.some(el => el.service_name === constants.OTHER_OPTIONS_FOR_SERVICE)) {
        this.otherServiceTextField = true
      } else {
        this.otherServiceTextField = false
      }
      this.$emit('contactServices', this.contact_service)
    },
    handleForm() {
      if (this.form.lead_generation_type === '1') {
        this.company_individual_select = 'Company'
        this.company_select_disable = false
      } else {
        this.company_individual_select = 'Title'
        this.company_select_disable = false
      }
      this.$emit('getCreateLead', this.form)
    },
    fetchResults() {
      if (this.form.vendor_name.length >= 3) {
        store.dispatch('ManageLeads/getVendors', { keyword: this.form.vendor_name, lead_generation_type: this.form.lead_generation_type })
          .then(response => {
            if (response.data.code === '200') {
              this.suggestions = []
              this.form.vendor_id = ''
              this.suggestions.push({ data: response.data.data })
              this.$emit('getCreateLead', this.form)
            }
          })
      }
    },
    change_autosuggest(val) {
      this.form.vendor_id = val.item.id
      this.form.vendor_name = val.item.name
      this.suggestions[0].data.forEach(e => {
        // eslint-disable-next-line eqeqeq
        if (e.id == val.item.id) {
          this.form.customer_type_id = e.customer_type_id
          this.form.landline = e.landline
          this.form.website = e.website
        }
      })
      this.$emit('getCreateLead', this.form)
    },
    onlyNum(a) {
      return onlyNumber(a)
    },
    renderSuggestion(suggestion) {
      return suggestion.item.name
    },
  },
}
</script>

<style>
</style>

<template>
  <b-col
    v-if="form.lead_service.length > 0"
    xl="12"
  >
    <b-card>
      <b-card-title class="">
        {{ t('Contact Details') }}
      </b-card-title>
      <b-row
        v-for="(item, index) in form.contact_data"
        :id="item.id"
        :key="item.id"
        :ref="index"
      >
        <b-col
          cols="12"
          class="d-flex align-items-baseline justify-content-end"
        >
          <b-button
            v-if="index > 0"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-white text-danger"
            class="mt-0 mt-md-2 px-1"
            @click="removeItem(index)"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-25"
            />
          </b-button>
        </b-col>
        <!-- Select Services -->
        <b-col cols="4">
          <b-form-group
            :label="t('Services')"
            label-for="vi-Services"
            class=""
          >
            <validation-provider
              #default="{ errors }"
              name="Services"
              vid="vi-Services"
              rules=""
            >
              <v-select
                v-model="form.contact_data[index].assign_service"
                class=" p-0 border-0"
                :class="errors[0] ? 'border-red-vselect' : ''"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="service_name"
                :reduce="s => s.id"
                multiple
                placeholder=""
                :options="contactservices"
                @change="handleForm"
              />
              <small class="text-danger">{{ t(errors[0]) }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- Name -->
        <b-col cols="4">
          <b-form-group
            :label="t('Name')"
            label-for="vi-Name"
            class="required"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              :vid="'vi-Name'+index"
              rules="required|min:3"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  :id="'vi-Name'+index"
                  v-model="form.contact_data[index].name"
                  :class="errors[0] ? 'border-red' : ''"
                  type="text"
                  autocomplete="off"
                  :placeholder="t('Name')"
                  @change="handleForm"
                />
              </b-input-group>

              <small class="text-danger">{{ t(errors[0]) }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Mobile -->
        <b-col cols="4">
          <b-form-group
            :label="t('Mobile')"
            label-for="vi-mobile"
            class=""
          >
            <validation-provider
              #default="{ errors }"
              name="Mobile"
              :vid="'vi-mobile'+index"
              rules="min:10"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  :id="'vi-mobile'+index"
                  v-model="form.contact_data[index].mobile"
                  :class="errors[0] ? 'border-red' : ''"
                  type="text"
                  maxlength="10"
                  autocomplete="off"
                  :placeholder="t('Mobile')"
                  @keypress="onlyNum"
                  @change="handleForm"
                />
              </b-input-group>
              <small class="text-danger">{{ t(errors[0]) }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- email -->
        <b-col cols="4">
          <b-form-group
            :label="t('Email')"
            label-for="vi-email"
            class="required"
          >
            <validation-provider
              #default="{ errors }"
              name="Email"
              :vid="'vi-email'+index"
              rules="required|email"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  :id="'vi-email'+index"
                  v-model="form.contact_data[index].email"
                  :class="errors[0] ? 'border-red' : ''"
                  type="text"
                  autocomplete="off"
                  :placeholder="t('Email')"
                  @change="handleForm"
                />
              </b-input-group>
              <small class="text-danger">{{ t(errors[0]) }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- landline -->
        <b-col cols="3">
          <b-form-group
            :label="t('Landline')"
            label-for="vi-landline"
            class=""
          >
            <validation-provider
              #default="{ errors }"
              name="Landline"
              :vid="'vi-landline'+index"
              rules="min:10"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  :id="'vi-landline'+index"
                  v-model="form.contact_data[index].landline"
                  :class="errors[0] ? 'border-red' : ''"
                  type="text"
                  maxlength="15"
                  autocomplete="off"
                  :placeholder="t('Landline')"
                  @keypress="onlyNum"
                  @change="handleForm"
                />
              </b-input-group>
              <small class="text-danger">{{ t(errors[0]) }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- ext -->
        <b-col cols="1">
          <b-form-group
            :label="t('Ext.')"
            label-for="vi-ext"
            class=""
          >
            <validation-provider
              #default="{ errors }"
              name="Ext"
              :vid="'vi-ext'+index"
              rules="min:3"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  :id="'vi-ext'+index"
                  v-model="form.contact_data[index].ext"
                  :class="errors[0] ? 'border-red' : ''"
                  type="text"
                  maxlength="10"
                  autocomplete="off"
                  :placeholder="t('Ext.')"
                  @keypress="onlyNum"
                  @change="handleForm"
                />
              </b-input-group>
              <small class="text-danger">{{ t(errors[0]) }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Select Department -->
        <b-col cols="4">
          <b-form-group
            :label="t('Department')"
            label-for="vi-department"
            class=""
          >
            <validation-provider
              #default="{ errors }"
              name="Department"
              :vid="'vi-department'+index"
              rules=""
            >
              <v-select
                v-model="form.contact_data[index].department"
                :class="errors[0] ? 'border-red-vselect' : ''"
                class="p-0 border-0"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="department_name"
                placeholder=""
                :reduce="d => d.id"
                :options="deparments"
                @change="handleForm"
              />
              <small class="text-danger">{{ t(errors[0]) }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Designation -->
        <b-col cols="4">
          <b-form-group
            :label="t('Designation')"
            :label-for="`vi-designation${index}`"
            class=""
          >
            <validation-provider
              #default="{ errors }"
              name="Designation"
              :vid="`vi-designation${index}`"
              rules="min:2"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  v-model="form.contact_data[index].designation"
                  :class="errors[0] ? 'border-red' : ''"
                  type="text"
                  autocomplete="off"
                  maxlength="50"
                  :placeholder="t('Designation')"
                  @change="handleForm"
                />
              </b-input-group>
              <small class="text-danger">{{ t(errors[0]) }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Business Card -->
        <b-col
          cols="4"
          class="p-0"
        >
          <b-col cols="12">
            <b-form-group
              :label="t('Business Card')"
              label-for="vi-business-card"
              class=""
            >
              <validation-provider
                #default="{ errors }"
                name="Business Card"
                :vid="'vi-business-card'+index"
                rules=""
              >
                <b-input-group class="input-group-merge">
                  <b-form-file
                    :id="'vi-business-card'+index"
                    ref="business_Card"
                    v-model="form.contact_data[index].card"
                    :class="form.preview_message[index] ? 'border-red-file' : ''"
                    :browse-text="t('Browse')"
                    name="business_Card"
                    accept="image/*,.pdf,.doc,.docx"
                    type="file"
                    :placeholder="t('Upload Business Card')"
                    @change="getprofile($event, index)"
                  />
                </b-input-group>
                <a
                  v-if="form.contact_data[index].business_card && !business_card_preview[index]"
                  target="blank"
                  class="pt-1"
                  :href="form.contact_data[index].business_card"
                >{{ 'Preview' }}
                </a>
                <div
                  v-if="business_card_preview[index]"
                  class="business_card_preview"
                >
                  <a
                    v-if="form.preview_message[index]"
                    target="blank"
                    href="#"
                    class="text-danger"
                  > {{ file_error }}</a>
                  <a
                    v-else
                    target="blank"
                    :href="business_card_preview[index]"
                    class=" pt-1"
                  >{{ 'Preview' }}
                  </a>
                </div>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-col>
        <b-col>
          <hr>
        </b-col>
      </b-row>
      <b-button
        v-if="form.contact_data.length < 5"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>{{ t('Add New') }}</span>
      </b-button>
    </b-card>
  </b-col>

</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import {
  BCard, BCol, BRow, BFormGroup, BInputGroup, BCardTitle, BFormInput, BFormFile, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useUtils } from '@core/libs/i18n'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import { onlyNumber } from '@/utils/common'
// import LoginDetails from './CreateUserForm/LoginDetails.vue'

export default {
  name: 'ContactDetails',
  components: {
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BCardTitle,
    BRow,
    ValidationProvider,
    BCol,
    BFormGroup,
    BInputGroup,
    BCard,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: ['req', 'editdata', 'formval', 'contactservices'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      business_card_photo: [],
      business_card_preview: [],
      file_error: '',
      form: {
        lead_id: '',
        company_individual: '',
        vendor_name: '',
        zipcode: '',
        latitude: '',
        longitude: '',
        city_name: '',
        state_name: '',
        country_id: '',
        country_name: '',
        lead_service: [],
        remark: '',
        ext: '',
        customer_type_id: '',
        landline: '',
        website: '',
        lead_generation_type: '',
        vendor_id: '',
        address: '',
        address_line_2: '',
        landmark: '',
        preview_message: [false, false, false, false, false],
        contact_data: [{
          id: 1,
          department: '',
          designation: '',
          email: '',
          ext: '',
          landline: '',
          lead_contact_id: '',
          mobile: '',
          name: '',
          assign_service: [],
        }],
        company_individual_select: 'Select Company/Individual',
      },
      deparments: [],
      nextTodoId: 2,
      services: [],
      statusOptions: [
        {
          title: 'Active',
          value: 'A',
        },
        {
          title: 'In-Active',
          value: 'I',
        },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    editdata(newVal) {
      this.form = newVal
    },
    formval: {
      deep: true,
      handler(val) {
        this.form = val
      },
    },
  },
  mounted() {
    store.dispatch('ManageLeads/getDepartments')
      .then(response => {
        if (response.data.code === '200') {
          this.deparments = response.data.data
        }
      })

    store.dispatch('CompanyuserManage/getServices').then(response => {
      if (response.data.code === '200') {
        this.services = response.data.data
      }
    })
  },
  methods: {
    handleForm() {
      this.$emit('getCreateLead', this.form)
    },
    handleFileUploads(e, i) {
      this.$emit('getCreateLead', e.target.files, 'images', '', '', i)
    },
    getprofile(event, index) {
      // this.business_card_photo = this.$refs.business_Card.files[0]
      if (event.target.files.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.business_card_photo[index] = event.target.files[0]
        this.form.contact_data[index].card = URL.createObjectURL(event.target.files[0])
        this.business_card_preview[index] = URL.createObjectURL(event.target.files[0])
        this.$emit('getCreateLead', this.business_card_photo[index], 'images', '', '', index)
        // eslint-disable-next-line eqeqeq
        if (event.target.files[0].type == 'application/x-zip-compressed') {
          this.form.preview_message[index] = true
          this.file_error = 'Zip file not allowed'
        } else {
          this.form.preview_message[index] = false
        }
      } else {
        this.business_card_photo[index] = 'https://staging-lr.yugtia.com/assets/images/users/1.jpg'
        this.form.contact_data[index].card = 'https://staging-lr.yugtia.com/assets/images/users/1.jpg'
      }
    },
    repeateAgain() {
      this.form.contact_data.push({
        id: this.nextTodoId += this.nextTodoId,
        department: '',
        designation: '',
        email: '',
        ext: '',
        landline: '',
        lead_contact_id: '0',
        mobile: '',
        name: '',
      })
    },
    removeItem(index) {
      this.form.contact_data.splice(index, 1)
      this.business_card_preview.splice(index, 1)
      this.business_card_photo.splice(index, 1)
      this.$emit('getCreateLead', this.business_card_photo[index], 'images', '', index, '')
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    onlyNum(a) {
      return onlyNumber(a)
    },
  },
}
</script>

<style>
.business_card_preview img{
  max-height: 50px;
  padding: 5px;
}
</style>

<template>
  <b-modal
    id="ViewLeadModal"
    :title="values.name + ' - ' + values.vendor_code + '  #' + values.id"
    size="lg"
    hide-footer
  >
    <b-row>
      <b-col
        cols="12"
        class="table-responsive"
      >
        <h5 class="mt-1">
          {{ t('Lead Details') }}
        </h5>
        <table class="table">
          <tr>
            <td class="font-weight-bolder w-25 va-baseline">
              {{ t('Customer Type') }}
            </td>
            <td class="va-baseline">
              {{ values.customer_type }}
            </td>
            <td class="font-weight-bolder w-25 va-baseline">
              {{ t('Vendor Type') }}
            </td>
            <td class="va-baseline">
              {{ values.vendor_type_name }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-bolder va-baseline">
              {{ t('Address') }}
            </td>
            <td class="va-baseline w-50">
              {{ values.address }}
            </td>
            <td class="font-weight-bolder va-baseline">
              {{ t('Services') }}
            </td>
            <td class="va-baseline">
              {{ values.lead_service_name }}
            </td>

          </tr>
          <tr>
            <td class="font-weight-bolder va-baseline">
              {{ t('Lead By') }}
            </td>
            <td class="va-baseline">
              {{ values.created_by_name }}
            </td>
            <td class="font-weight-bolder va-baseline">
              {{ t('Lead On') }}
            </td>
            <td class="va-baseline">
              {{ values.created_date }}
            </td>
          </tr>
        </table>
      </b-col>
      <b-col
        cols="12"
        class="table-responsive"
      >
        <h5 class="mt-1">
          {{ t('Contact') }}
        </h5>
        <table class="table table-striped">
          <thead>

            <tr>
              <th>{{ t('Id') }}</th>
              <th>{{ t('Name') }}</th>
              <th>{{ t('Mobile') }}</th>
              <th>{{ t('Email') }}</th>
              <th>{{ t('Services') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="values.length === 0 && values.LeadContactData.length === 0">
              <td
                colspan="5"
                class="text-center"
              >
                {{ t('No Records Found') }}
              </td>
            </tr>
            <tr
              v-for="(cdata, index) in values.LeadContactData"
              :key="index"
            >
              <td>{{ cdata.id }}</td>
              <td>{{ cdata.name }}</td>
              <td>{{ cdata.mobile }}</td>
              <td>{{ cdata.email }}</td>
              <td><div class="">
                <small
                  v-for="service in cdata.service_data"
                  :key="service.id"
                >
                  <b-badge
                    class="m-10"
                    variant="success"
                  >{{ service.service_id == constants.OTHER_SERVICE_ID ? service.other_service : service.service_name }}</b-badge>
                </small>
              </div></td>
            </tr>
          </tbody>
        </table>

      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BCol, BRow,
} from 'bootstrap-vue'
import { useUtils } from '@core/libs/i18n'
import { required } from '@validations'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import constants from '@/constants'
// eslint-disable-next-line import/extensions
export default {
  components: {
    BModal,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    values: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      required,
      lead_val: '',
      constants,
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.va-baseline{
  vertical-align: baseline !important;
}
#ViewLeadModal___BV_modal_header_{
  background: #b9b4f8;
}
</style>

<template>
  <div>
    <b-modal
      id="editAssignLead"
      title="Edit Lead"
      size="xl"
      hide-footer
    >
      <create-lead
        :edit_lead_id="edit_lead_id"
      />
    </b-modal>
  </div>
</template>

<script>
import 'bootstrap-vue'
import CreateLead from '../CreateLeads/CreateLead.vue'

export default {
  components: {
    CreateLead,
  },
  props: ['edit_lead_id'],
}
</script>

<style>
.pac-container{
  z-index: 999999999;
}
</style>

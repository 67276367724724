<template>
  <b-form
    autocomplete="off"
    @submit.prevent="SearchLeadData"
  >
    <b-row class="mb-2">
      <b-col
        cols="6"
        md="3"
      >
        <b-form-group>
          <b-input-group class="input-group-merge">
            <vue-autosuggest
              ref="company_select"
              v-model="form.name"
              :suggestions="suggestions"
              :input-props="{id: 'autosuggest__input_ajax',
                             placeholder: t('Title'),
                             class: 'form-control',}"
              :render-suggestion="renderSuggestion"
              @selected="change_autosuggest"
              @input="fetchResults"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        md="3"
      >
        <b-form-group>
          <v-select
            v-model="form.vendor_type"
            class="p-0 border-0"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="vendor_type_name"
            :reduce="op => op.vendor_type_id"
            :placeholder="t('Type')"
            :options="vendorOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        md="3"
      >
        <b-form-group>
          <v-select
            v-model="form.customer_type_id"
            class="p-0 border-0"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="customer_type"
            :reduce="op => op.id"
            :placeholder="t('Customer Type')"
            :options="customerTypeOptions"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        md="3"
      >
        <b-form-group>
          <b-input-group class="input-group-merge">
            <vue-autosuggest
              ref="city_name"
              v-model="form.city_name"
              :suggestions="cityOptions"
              :input-props="{id: 'autosuggest__input_ajax',
                             placeholder: t('City'),
                             class: 'form-control',}"
              :render-suggestion="renderCitySuggestion"
              :get-suggestion-value="renderCitySuggestion"
              @selected="changeCityAutosuggest"
              @input="suggestForm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        md="3"
      >
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-Country"
              v-model="form.lead_by"
              type="text"
              autocomplete="off"
              :placeholder="t('Lead By')"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        md="3"
      >
        <b-form-group>
          <v-select
            v-model="form.period"
            class="p-0 border-0"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :reduce="op => op.value"
            :placeholder="t('Period')"
            :options="periodOptions[$i18n.locale]"
            @input="onChangePeriod"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        md="3"
      >
        <b-form-group>
          <b-input-group class="input-group-merge">
            <flat-pickr
              v-model="form.start_date"
              :placeholder="t('Select Start Date')"
              class="form-control"
              :class="isDateDisable ? 'pointer-events-none' : 'remove-readonly'"
              :config="{ dateFormat: 'Y-m-d'}"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        md="3"
      >
        <b-form-group>
          <b-input-group class="input-group-merge">
            <flat-pickr
              v-model="form.end_date"
              class="form-control"
              :placeholder="t('Select End Date')"
              :class="isDateDisable ? 'pointer-events-none' : 'remove-readonly'"
              :config="{ dateFormat: 'Y-m-d'}"
            />
          <!-- disable:[{from:'1970-01-01',to:form.start_date}] -->
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        v-if="filteralllead"
        cols="6"
        md="3"
      >
        <v-select
          v-model="form.params.service_id"
          class="p-0 border-0"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="service_name"
          :reduce="s => s.id"
          multiple
          placeholder="Select Services"
          :options="services"
        />
      </b-col>
      <b-col
        v-if="filteralllead"
        cols="6"
        md="3"
      >
        <v-select
          v-model="form.is_assigned"
          class="p-0 border-0"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="label"
          :reduce="op => op.id"
          :placeholder="t('Select Assigned/All')"
          :options="allFilterList"
          @input="clearAssignedTo"
        />
      </b-col>
      <b-col
        v-if="assigned_to && form.is_assigned == 0"
        cols="6"
        md="3"
      >
        <b-form-group>
          <b-input-group class="input-group-merge">
            <vue-autosuggest
              v-model="form.assigned_to"
              autocomplete="off"
              multiple
              :suggestions="assignedToList"
              :input-props="{id: 'autosuggest__input_ajax',
                             placeholder: t('Assigned To'),
                             class: 'form-control',}"
              :render-suggestion="renderAssignToSuggestion"
              :get-suggestion-value="renderAssignToSuggestion"
              @selected="changeAssignToAutosuggest"
              @input="autocompleteAssignUserList"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="text-center mt-1"
      >
        <b-button
          variant="success mx-1"
          type="submit"
        >
          {{ t('Search') }}
        </b-button>
        <b-button
          variant="danger mx-1"
          @click="resetFilter"
        >
          {{ t('Reset') }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import vSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import {
  BRow, BCol, BInputGroup, BFormGroup, BButton, BForm, BFormInput,
} from 'bootstrap-vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { useUtils } from '@core/libs/i18n'
import store from '@/store'
// eslint-disable-next-line import/no-duplicates
import { periodOptions } from '@/utils/common'
// eslint-disable-next-line import/no-duplicates
import { getDateByPeriod } from '@/utils/common'
import constants from '@/constants'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    vSelect,
    flatPickr,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BButton,
    VueAutosuggest,
    // BCollapse,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  props: [
    'tablefilter', 'filterparams', 'assigned_to', 'filteralllead', 'pending_all',
  ],
  data() {
    return {
      // eslint-disable-next-line new-cap
      periodOptions,
      isDateDisable: true,
      suggestions: [],
      allFilterList: [{ id: 0, label: 'All', value: 'all' }, { id: 2, label: 'Assigned', value: 'assigned' }],
      vendorOptions: [{
        vendor_type_name: 'Company',
        vendor_type_id: 1,
      }, {
        vendor_type_name: 'Individual',
        vendor_type_id: 2,
      }],
      customerTypeOptions: [],
      cityOptions: [],
      assignedToList: [],
      form: {
        city: '',
        name: '',
        lead_by: '',
        customer_type_id: '',
        vendor_type: '',
        start_date: null,
        end_date: null,
        is_assigned: constants.ASSIGNED_PARAM_VALUE,
        period: '',
        sortBy: 'id',
        sortOrder: 'desc',
        size: '10',
        assigned_to: '',
        params: {
          assigned_to: '',
          service_id: [],
        },
      },
      services: [],
    }
  },
  watch: {
    filterparams(val) {
      this.form = val
    },
  },
  mounted() {
    this.form.start_date = moment().startOf('month').format('YYYY-MM-DD')
    this.form.end_date = moment().endOf('month').format('YYYY-MM-DD')
    this.form.period = '4'
    if (this.$route.params.period) {
      this.form.period = this.$route.params.period.toString()
      const dates = getDateByPeriod(this.form.period)
      this.form.start_date = dates['0']
      this.form.end_date = dates['1']
      this.isDateDisable = dates['2']
    }
    this.SearchLeadData()

    store.dispatch('ManageLeads/getCustomersTypeList')
      .then(response => {
        if (response.data.code === '200') {
          this.customerTypeOptions = response.data.data
        }
      })
    store.dispatch('CompanyuserManage/getServices').then(response => {
      if (response.data.code === '200') {
        this.services = response.data.data
      }
    })
  },
  methods: {
    clearAssignedTo() {
      this.form.params.assigned_to = ''
      this.form.assigned_to = ''
    },
    onChangePeriod(val) {
      const dates = getDateByPeriod(val)
      this.form.start_date = dates['0']
      this.form.end_date = dates['1']
      this.isDateDisable = dates['2']
    },
    SearchLeadData() {
      this.$emit('searchData', this.form)
    },
    suggestForm(val) {
      this.form.city = ''
      if (val.length >= 3) {
        store.dispatch('getCountryList/getCityAuto', { city: val }).then(response => {
          if (response.data.code === '200') {
            this.cityOptions.splice(0, this.cityOptions.length)
            this.cityOptions.push({ data: response.data.data })
            this.selected = null
          }
        })
      }
    },
    autocompleteAssignUserList(val) {
      if (val.length >= 3) {
        store.dispatch('ManageLeads/autocompleteAssignUserList', { keyword: val }).then(response => {
          if (response.data.code === '200') {
            this.assignedToList.splice(0, this.assignedToList.length)
            this.assignedToList.push({ data: response.data.data })
          }
        })
      }
    },
    resetFilter() {
      this.form = {
        params: {
          assigned_to: '',
        },
        city: '',
        name: '',
        lead_by: '',
        customer_type_id: '',
        vendor_type: '',
        start_date: null,
        end_date: null,
        is_assigned: constants.ASSIGNED_PARAM_VALUE,
        period: '',
        sortBy: 'id',
        sortOrder: 'desc',
        size: '10',
      }
      this.SearchLeadData()
    },
    fetchResults() {
      if (this.form.name.length >= 3) {
        store.dispatch('ManageLeads/getVendorSearch', { keyword: this.form.name })
          .then(response => {
            if (response.data.code === '200') {
              this.suggestions = []
              this.suggestions.push({ data: response.data.data })
            }
          })
      }
    },
    renderSuggestion(suggestion) {
      return suggestion.item.name
    },
    renderAssignToSuggestion(suggestion) {
      return `${suggestion.item.firstname} ${suggestion.item.lastname}`
    },
    change_autosuggest(val) {
      this.form.name = val.item.name
    },
    changeAssignToAutosuggest(val) {
      this.form.params.assigned_to = val.item.companyuserid
    },
    // filterLead() {
    //   document.getElementById('loading-bg').classList.add('loading-bg')
    //   store.dispatch('ManageLeads/ListLeadAPI', this.form).then(response => {
    //     if (response.data.code === '200') {
    //       this.$emit('recieveData', response.data.data)

    //       document.getElementById('loading-bg').classList.remove('loading-bg')
    //     } else {
    //       this.$toast({
    //         component: ToastificationContent,
    //         position: 'top-right',
    //         props: {
    //           title: 'Something Went Wrong',
    //           icon: 'Danger',
    //           variant: 'danger',
    //           text: '',
    //         },
    //       })
    //       document.getElementById('loading-bg').classList.remove('loading-bg')
    //     }
    //   }).catch(error => {
    //     this.$toast({
    //       component: ToastificationContent,
    //       position: 'top-right',
    //       props: {
    //         title: error,
    //         icon: 'Danger',
    //         variant: 'danger',
    //         text: '',
    //       },
    //     })
    //     document.getElementById('loading-bg').classList.remove('loading-bg')
    //   })
    // },
    changeCityAutosuggest(val) {
      this.form.city = val.item.location_id
      this.form.city_name = val.item.city
      this.$emit('getCreateUser', this.form)
    },
    renderCitySuggestion(suggestion) {
      return suggestion.item.city
    },
  },

}
</script>

<style>
</style>

<template>
  <div>
    <div class="d-flex justify-content-between mb-1">

      <b-button
        v-b-toggle.collapse-1
        variant="outline-primary"
      >
        {{ t('Filters') }}
      </b-button>

      <b-button
        variant="primary"
        @click="redirectToAddLead"
      >

        <feather-icon icon="PlusIcon" />
        {{ t('Add Lead') }}
      </b-button>
    </div>

    <b-collapse
      id="collapse-1"
      class="mt-2"
    >
      <lead-filter
        :filterparams="form"
        :assigned_to="true"
        :filteralllead="true"
        :pending_all="2"
        @searchData="GetSearchFilter"
      />
    </b-collapse>
    <b-card>
      <b-table
        sticky-header
        responsive="true"
        :items="items.data"
        :fields="userFields"
        @row-contextmenu="contextmenu"
        @sort-changed="sortChanged"
      >
        <template #cell(lead_generation_id)="data">
          <span class="">{{ data.item.lead_generation_id }}</span>
        </template>
        <template #cell(lead_code)="data">
          <span
            :id="`assigned-names-${data.item.id}`"
            @click="$router.push({name:`assignedlead/id`, params: { id:data.item.lead_id }, query: {q: true }})"
          ><a href="javascript:void(0)">{{ data.item.lead_code }}</a></span>
          <!-- <b-tooltip
            v-if="data.item.lead_assign_name.length"
            placement="right"
            :target="`assigned-names-${data.item.id}`"
            variant="primary"
          >
            {{ data.item.lead_assign_name | arrayToStringVal }} </b-tooltip> -->
        </template>
        <template #cell(name)="data">
          <span class="">{{ data.item.name }}</span>
        </template>
        <template #cell(lead_assign_name)="data">
          <span class="">{{ data.item.lead_assign_name | arrayToStringVal }}</span>
        </template>
        <template #cell(assign_service_name)="data">
          <span class="">
            <b-badge
              v-for="(service, i) in data.item.assign_service_name"
              :key="i"
              variant="success"
              class="m-10"
            >
              {{ service }}
            </b-badge>
          </span>
        </template>
        <template #cell(vendor_type_name)="data">
          <span class="">{{ data.item.vendor_type_name }}</span>
        </template>
        <template #cell(customer_type)="data">
          <span class="">{{ data.item.customer_type }}</span>
        </template>
        <template #cell(city_name)="data">
          <span class="">{{ data.item.city_name }}</span>
        </template>
        <template #cell(created_date)="data">
          <span class="">{{ data.item.created_date }}</span>
        </template>
        <template #cell(created_by_name)="data">
          <span class="">{{ data.item.created_by_name }}</span>
        </template>

        <template
          v-if="items.total == 0"
          #table-caption
        >
          <div
            class="text-center"
          >
            {{ t('No Record Found') }}
          </div>
        </template>
      </b-table>
      <b-row v-if="items.total">
        <b-col
          cols="4"
          class="d-flex"
        >
          <div class="align-self-center pr-2">
            {{ t('Total Entries') + " " + items.total }}
          </div>
          <b-form-group class="align-self-center mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ t('Per page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="form.size"
              size="sm"
              :options="tableFilter.pageOptions"
              class="w-70"
              @change="getListPendingLeadAPI"
            />
          </b-form-group>

        </b-col>
        <b-col cols="8">
          <pagination
            :data="items"
            :limit="2"
            class="pt-1 justify-content-end"
            @pagination-change-page="getResults"
          />
        </b-col>

      </b-row>
    </b-card>
    <vue-context
      ref="leadsContextMenu"
    >
      <li
        v-for="data in menuData"
        :key="data.text"
      >
        <b-link

          v-if="data.text == 'View More Details'"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
        <b-link

          v-else-if="data.text != 'View More Details'"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>
    <view-lead-modal
      :values="valuesModal"
    />
    <edit-assigned-lead-modal
      :edit_lead_id="valuesContext.lead_id"
    />
  </div>
</template>
<script>
import {
  BButton, BTable, BCard, BCollapse, VBToggle, BRow, BCol, BLink, BFormSelect, BFormGroup,
} from 'bootstrap-vue'
import { useUtils } from '@core/libs/i18n'
import VueContext from 'vue-context'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@/utils/axiosCompany'
import store from '@/store'
import constants from '@/constants'
import LeadFilter from '../CreateLeads/LeadFilter.vue'
import {
  tableFilter, getDateByPeriod, showLoader, hideLoader,
} from '@/utils/common'
import EditAssignedLeadModal from './EditAssignedLeadModal.vue'
import ViewLeadModal from '../CreateLeads/ViewLeadModal.vue'
import checkPermissions from '@/utils/checkPermissons'

export default {
  name: 'AssignedLeadList',
  components: {
    BButton,
    BTable,
    BCard,
    BCollapse,
    LeadFilter,
    BRow,
    BCol,
    BLink,
    BFormSelect,
    BFormGroup,
    VueContext,
    ViewLeadModal,
    EditAssignedLeadModal,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      items: {},
      tableFilter,
      valuesContext: {},
      valuesModal: {},
      menuData: [],
      form: {
        params: {
          assigned_to: '',
        },
        city: '',
        name: '',
        is_assigned: constants.ASSIGNED_PARAM_VALUE,
        lead_by: '',
        customer_type_id: '',
        vendor_type: '',
        filter_all_assigned: '',
        start_date: null,
        end_date: null,
        period: '',
        sortBy: 'id',
        sortOrder: 'desc',
        size: '10',

      },
      searchParams: {},
    }
  },
  computed: {
    userFields() {
      return [
        { key: 'lead_generation_id', label: this.$i18n.t('Id'), sortable: true },
        { key: 'lead_code', label: this.$i18n.t('Lead Code'), sortable: true },
        { key: 'name', label: this.$i18n.t('Title'), sortable: true },
        { key: 'lead_assign_name', label: this.$i18n.t('Lead Assign To'), sortable: false },
        { key: 'assign_service_name', label: this.$i18n.t('Services'), sortable: false },
        { key: 'opportunity_count', label: this.$i18n.t('Opportunity Count'), sortable: false },
        { key: 'vendor_type_name', label: this.$i18n.t('Type'), sortable: true },
        { key: 'customer_type', label: this.$i18n.t('Customer Type'), sortable: true },
        { key: 'city_name', label: this.$i18n.t('City'), sortable: true },
        { key: 'created_date', label: this.$i18n.t('Lead On'), sortable: true },
        { key: 'created_by_name', label: this.$i18n.t('Lead By'), sortable: true },
      ]
    },
    menuOptions() {
      return [
        { id: 3, icon: 'EditIcon', text: this.$i18n.t('Edit') },
        { id: 1, icon: 'EyeIcon', text: this.$i18n.t('View Details') },
        { id: 2, icon: 'EyeIcon', text: this.$i18n.t('View More Details') },
      ]
    },
  },
  mounted() {
    if (this.$route.params.period) {
      this.form.period = this.$route.params.period.toString()
      const dates = getDateByPeriod(this.$route.params.period.toString())
      this.form.start_date = dates['0']
      this.form.end_date = dates['1']
      this.isDateDisable = dates['2']
    }
    this.$root.$on('refreshAssignedLeadsList', () => {
      this.getListPendingLeadAPI()
    })
  },
  methods: {
    sortChanged(val) {
      this.form.sortBy = val.sortBy
      this.form.sortOrder = val.sortDesc ? 'desc' : 'asc'
      this.getListPendingLeadAPI()
    },
    GetSearchFilter(val) {
      this.form = val
      this.getListPendingLeadAPI()
    },
    checkPermission(val) {
      return checkPermissions(val)
    },
    redirectToAddLead() {
      this.$router.push('/leads/add-lead')
    },
    async getListPendingLeadAPI() {
      showLoader()
      // eslint-disable-next-line eqeqeq
      if (this.form.is_assigned == 0) {
        this.form.is_assigned = 0
      } else {
        this.form.is_assigned = constants.ASSIGNED_PARAM_VALUE
      }
      await store.dispatch('ManageLeads/ListAssignedLeadAPI', this.form).then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.items = response.data.data
          document.getElementById('loading-bg').classList.remove('loading-bg')
          // eslint-disable-next-line eqeqeq
        } else if (response.data.code == constants.ERROR_500) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Something Went Wrong!',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      }).catch(e => {
      // eslint-disable-next-line eqeqeq
        if (e.msg == 'Unauthenticated.') {
          this.$router.back()
          document.getElementById('loading-bg').classList.remove('loading-bg')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'No Permission',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Something Went Wrong!',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      })
      hideLoader()
    },
    async getResults(page = 1) {
      showLoader()
      // eslint-disable-next-line no-undef
      await axios.post(`/lead-generate/pending-lead-list?page=${page}`, this.form, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(response => {
          this.items = response.data.data
          hideLoader()
        })
      hideLoader()
    },
    contextmenu(item, index, event) {
      this.menuData = []
      event.preventDefault()
      if (!item.is_edit) {
        this.menuData = this.menuOptions.filter(val => val.id !== 3)
      } else {
        this.menuData = this.menuOptions
      }
      this.valuesContext = item
      this.$refs.leadsContextMenu.open(event, item)
    },
    optionClicked(id) {
      if (id === 1) {
        showLoader()
        store.dispatch('ManageLeads/getLeadById', { lead_generation_id: this.valuesContext.lead_id }).then(response => {
          this.valuesModal = response.data.data
          this.$bvModal.show('ViewLeadModal')
          hideLoader()
        }).catch(() => {
          hideLoader()
        })
      } else if (id === 2) {
        this.$router.push({ name: 'lead/id', params: { id: this.valuesContext.lead_id }, query: { q: true } })
      } else if (id === 3) {
        this.$bvModal.show('editAssignLead')
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-context.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>

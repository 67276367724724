<template>
  <validation-observer
    ref="createLead"
  >
    <b-form
      enctype="multipart/form-data"
      autocomplete="off"
      @submit.prevent="createLead"
    >
      <b-row>
        <user-details
          :formval="form"
          :edit_lead_id="edit_lead_id"
          :map_marker="map_marker"
          @getCreateLead="getFor"
          @contactServices="get_contact_services"
        />
        <contact-details
          :formval="form"
          :contactservices="contact_services"
          @getCreateLead="getFor"
        />

        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            {{ t('Submit') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="redirectToLeadList"
          >
            {{ t('Cancel') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BCol, BForm, BRow, BButton,
} from 'bootstrap-vue'
import { useUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import ContactDetails from './CreateLeadForm/ContactDetails.vue'
import UserDetails from '@/views/Company/Leads/CreateLeads/CreateLeadForm/UserDetails.vue'
import { hideLoader, showLoader } from '@/utils/common'
import constants from '@/constants'

export default {
  name: 'CreateLead',
  components: {
    BRow,
    BButton,
    ContactDetails,
    BCol,
    BForm,
    ValidationObserver,
    UserDetails,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: ['edit_lead_id'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      req: true,
      images: [],
      map_marker: [],
      form: {
        lead_id: this.edit_lead_id,
        contact_data: [{
          id: 1,
          department: '',
          designation: '',
          email: '',
          ext: '',
          landline: '',
          lead_contact_id: '',
          business_card: '',
          assign_service: [],
          mobile: '',
          name: '',
        }],
        lead_generation_type: '',
        vendor_id: '',
        vendor_name: '',
        landline: '',
        website: '',
        customer_type_id: '',
        address: '',
        address_line_2: '',
        landmark: '',
        latitude: '',
        longitude: '',
        remark: '',
        ext: '',
        company_individual: '',
        zipcode: '',
        city_name: '',
        state_name: '',
        country_id: '',
        country_name: '',
        lead_service: [],
        preview_message: [],
      },
      contact_services: [],
      contact_data: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async mounted() {
    if (this.edit_lead_id) {
      this.form.contact_data = []
      await store.dispatch('ManageLeads/getLeadDataById', { lead_id: this.edit_lead_id }).then(response => {
        if (response.data.code === '200') {
          const leadData = response.data.data
          this.form.lead_generation_type = leadData.vendor_type_name
          this.form.vendor_id = leadData.vendor_id
          this.form.vendor_name = leadData.name
          this.form.customer_type_id = leadData.customer_type_id
          this.form.landline = leadData.landline
          this.form.website = leadData.website
          this.form.address = leadData.address
          document.getElementById('lead-address').value = leadData.address
          this.form.lead_generation_type = leadData.vendor_type
          this.form.latitude = leadData.latitude
          this.form.longitude = leadData.longitude
          this.map_marker = [{ lat: parseFloat(leadData.latitude), lng: parseFloat(leadData.longitude) }]
          this.form.remark = leadData.remark ? leadData.remark : ''
          this.form.ext = leadData.extention
          this.form.zipcode = leadData.zipcode
          this.form.city_name = leadData.city_name
          this.form.state_name = leadData.state_name
          this.form.country_name = leadData.country_name
          this.form.services = leadData.LeadServiceData
          leadData.LeadServiceData.forEach(service => {
            this.form.lead_service.push(service.id)
          })
          this.get_contact_services(leadData.LeadServiceData)
        }
      })
      await store.dispatch('ManageLeads/getLeadContactData', { lead_id: this.edit_lead_id }).then(response => {
        response.data.data.data.forEach(contact => {
          this.form.contact_data.push({
            lead_contact_id: contact.contact_id,
            name: contact.name,
            email: contact.email,
            mobile: contact.mobile,
            landline: contact.landline,
            ext: contact.extention,
            department: contact.department_id,
            designation: contact.designation,
            assign_service: contact.serviceIds,
            business_card: contact.business_card,
          })
        })
      })
    }
    if (this.$route.params.id) {
      this.form.companyuserid = this.$route.params.id
      this.req = false
      store.dispatch('CompanyuserManage/getUserById', { companyuserid: this.form.companyuserid })
        .then(response => {
          if (response.data.code === '200') {
            this.form = response.data.data
          }
        })
    } else {
      this.form.companyuserid = '0'
    }
  },
  created() {
    // this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
  methods: {
    getFor(value, name, type, remove, index) {
      if (!name) {
        Object.assign(this.form, value)
      } else if (type === 'checkbox') {
        if (value) {
          this.form[name].push(value)
        } else {
          this.form[name].splice(this.form[name].indexOf(remove), 1)
        }
      } else if (name === 'images') {
        if (remove) {
          this.images.splice(this.images.indexOf(remove), 1)
        } else {
          this.images[index] = value
        }
      } else {
        this.form[name] = value
      }
    },
    get_contact_services(val) {
      this.contact_services = val
      this.form.contact_data.forEach((element, i) => {
        this.form.contact_data[i].assign_service = element.assign_service.filter(value => this.form.lead_service.includes(value))
      })
    },
    // remove_contact_services(val) {
    //   this.contact_services = this.contact_services.filter(cservice => cservice.id !== val.id)
    //   // eslint-disable-next-line eqeqeq
    //   if (this.contact_services.length == 0) {
    //     this.form.contact_data = [{
    //       id: 1,
    //       department: '',
    //       designation: '',
    //       email: '',
    //       ext: '',
    //       landline: '',
    //       lead_contact_id: '',
    //       business_card: '',
    //       assign_service: [],
    //       mobile: '',
    //       name: '',
    //     }]
    //   }
    //   // eslint-disable-next-line no-prototype-builtins
    //   this.form.contact_data.filter(cdata => { if (cdata.hasOwnProperty('assign_service')) { if (cdata.assign_service.indexOf(val.id) > -1) cdata.assign_service.splice(cdata.assign_service.indexOf(val.id), 1) } return cdata })
    // },
    redirectToLeadList() {
      if (this.edit_lead_id) {
        this.$bvModal.hide('editAssignLead')
      } else {
        this.$router.push('/leads')
      }
    },
    createLead() {
      if (!this.form.preview_message.includes(true)) {
        this.$refs.createLead.validate().then(success => {
          if (success) {
          // eslint-disable-next-line prefer-const
            let formData = new FormData()

            this.images.forEach((element, index) => {
              formData.append(`file${index}`, element)
            })
            formData.append('file_count', this.images.length)
            // eslint-disable-next-line prefer-const
            let FormsDatas = this.form

            Object.entries(FormsDatas).forEach(
              ([key, value]) => { if (key === 'contact_data') { formData.append(key, JSON.stringify(value)) } else { formData.append(key, value) } },
            )
            if (this.edit_lead_id) {
              this.update(formData)
            } else {
              this.create(formData)
            }
            // } else {
            //   this.$toast({
            //     component: ToastificationContent,
            //     position: 'top-right',
            //     props: {
            //       title: 'Please Fill the Details',
            //       icon: 'Warning',
            //       variant: 'warning',
            //       text: '',
            //     },
            //   })
          }
        })
      }
    },
    async create(formData) {
      showLoader()
      await store.dispatch('ManageLeads/createLead', formData).then(response => {
        if (response.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${response.data.msg}`,
              icon: 'Success',
              variant: 'success',
              text: '',
            },
          })
          hideLoader()
          this.$router.push('/leads')
        } else if (response.data.code === '422') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${response.data.msg}`,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
          hideLoader()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${response.data.msg}`,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
          hideLoader()
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: e.msg,
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
    async update(formData) {
      showLoader()
      await store.dispatch('ManageLeads/updateLead', formData).then(response => {
        if (response.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${response.data.msg}`,
              icon: 'Success',
              variant: 'success',
              text: '',
            },
          })
          hideLoader()
          this.$root.$emit('refreshAssignedLeadsList')
          this.$bvModal.hide('editAssignLead')
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${response.data.msg}`,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
          hideLoader()
        }
      }).catch(e => {
        // eslint-disable-next-line eqeqeq
        if ('code' in e && e.code == constants.ERROR_422) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${e.msg}`,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
          hideLoader()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${e.msg}`,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
          hideLoader()
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
#profile_photo_preview{
    text-align: center;
}
#profile_photo_preview img{
    padding: 10px;
    max-height: 164px;
    width: auto;
}
@media (max-width: 400px){
  #profile_photo_preview img{
    width: 100%;
}
}
</style>
